export enum TABS {
  MAIN = 'main',
  SETTINGS = 'settings',
  SUBMIT_MESSAGE = 'submitMessage',
  AUTOMATIONS = 'automations',
  SUPPORT = 'support',
  UPGRADE = 'upgrade',
  PAYMENT = 'payment'
}

export enum MAIN_APPLICATION_TYPES {
  AUTOMATIONS = 'automations',
  WORKFLOWS = 'workflows',
  PRICE_QUOTES = 'priceQuotes',
}

export const PRESET_NAME_TO_APPLICATION_NAME = {
  'contact': MAIN_APPLICATION_TYPES.AUTOMATIONS,
  'enterContest': MAIN_APPLICATION_TYPES.AUTOMATIONS,
  'contest-entry': MAIN_APPLICATION_TYPES.AUTOMATIONS,
  'feedback': MAIN_APPLICATION_TYPES.AUTOMATIONS,
  'customer-satisfaction': MAIN_APPLICATION_TYPES.AUTOMATIONS,
  'jobApplication': MAIN_APPLICATION_TYPES.WORKFLOWS,
  'job-application': MAIN_APPLICATION_TYPES.WORKFLOWS,
  'getAQuote': MAIN_APPLICATION_TYPES.PRICE_QUOTES,
  'price-quote': MAIN_APPLICATION_TYPES.PRICE_QUOTES,
  'quiz': MAIN_APPLICATION_TYPES.AUTOMATIONS,
  'salesLead': MAIN_APPLICATION_TYPES.WORKFLOWS,
  'sales-lead': MAIN_APPLICATION_TYPES.WORKFLOWS,
  'support': MAIN_APPLICATION_TYPES.WORKFLOWS,
  'testimonial': MAIN_APPLICATION_TYPES.AUTOMATIONS,
}

export enum MAIN_FOOTER_TYPES {
  FORM_BUILDER = 'FORM_BUILDER',
  PAYMENT = 'PAYMENT',
  REGISTRATION = 'REGISTRATION'
}