import * as _ from 'lodash'
import { createForm } from '../services/form-service'
import { createSuffixedName } from '../../../utils/utils'
import { SHOULD_CREATE_COLLECTION } from '../preset/presets-data'
import translations from '../../../utils/translations'
import { isInputField, undoable } from '../utils'
import { FormPreset } from '../../../constants/form-types'
import { EVENTS } from '../../../constants/bi'
import { ComponentRef, FormSnapshot, Plugin } from '../api-types'
import { ROLE_FORM } from '../../../constants/roles'
import CollectionsApi from '../collections/api'
import CoreApi from '../core-api'
import { APP_CONTROLLER_DEFINITION, APP_WIDGET_DEFINITION } from './controller-definition'
import RemoteApi from '../../../panels/commons/remote-api'
import { ADI_INITIATOR, MASTER_PAGE, TEMPLATE_ADI_INITIATOR } from './constants'
import { FormPlugin } from '../../../constants/plugins'
import { NOTIFICATION_EVENTS } from '../../constans/EVENTS'

const normalizeFormName = (
  formNames: string[],
  nameFromPreset: string | undefined,
  presetKey: FormPreset
) => {
  const title =
    nameFromPreset ||
    translations.t('formName', {
      name: translations.t(`addForm.templates.${presetKey}.label`),
    })

  return createSuffixedName(formNames, title)
}

const isADI = initiator => _.isEmpty(initiator) || initiator === ADI_INITIATOR
const isTemplateADI = initiator => initiator === TEMPLATE_ADI_INITIATOR
const addedFormsPromisesContainer = {}

export default class AddFormApi {
  private biLogger: any
  private boundEditorSDK: any
  private coreApi: CoreApi
  private collectionsApi: CollectionsApi
  private remoteApi: RemoteApi
  private experiments: any
  private isAppWidgetEnabled: boolean

  constructor(boundEditorSDK, coreApi, collectionsApi, remoteApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.collectionsApi = collectionsApi
    this.remoteApi = remoteApi
    this.experiments = experiments
  }

  @undoable()
  public async addForm(
    presetKey: FormPreset,
    {
      containerRef = null,
      targetPageRef = null,
      coords = null,
      source_name = null,
      select_form = true,
    } = {},
    formSnapshot?: FormSnapshot
  ) {
    if (!containerRef) {
      await this.coreApi.saveSiteIfUnsaved()
    }

    const locale = await this.boundEditorSDK.info.getLanguage()
    const { plugins, box, fields } = await Promise.resolve(
      createForm(
        presetKey,
        {
          coords,
          locale,
        },
        reason => this.coreApi.logFetchPresetsFailed(null, reason),
        formSnapshot
      )
    )

    if (_.find(plugins, { id: FormPlugin.GET_SUBSCRIBERS })) {
      this.coreApi.managePanels.openGetSubscribersFirstTimePanel()
    }

    const [pageRef, msid] = await Promise.all([
      targetPageRef || this.boundEditorSDK.pages.getCurrent(),
      this.coreApi.getMetaSiteId(),
    ])

    if (formSnapshot) {
      return this._addFromSnapshot(box, fields, pageRef, containerRef)
    }

    const formName = await this._getFormName(_.get(box, 'connectionConfig.formName'), presetKey)
    let formLabelId = ''

    try {
      formLabelId = await this._createTag(formName)
    } catch (ex) {}

    let emailId = ''

    try {
      emailId = await this.coreApi.getOwnerEmailId()
    } catch (err) {}

    const containerDefinition = _.merge({}, box, {
      connectionConfig: {
        formName,
        msid,
        formLabelId,
        emailId,
        labels: [...box.connectionConfig.labels, formLabelId],
      },
    })

    const initiator = this.coreApi.initiator()
    this.isAppWidgetEnabled =
      this.experiments.enabled('specs.cx.FormBuilderAppWidget') &&
      !isADI(initiator) &&
      !isTemplateADI(initiator)

    const controllerRef = await this._addController(containerRef, containerDefinition, pageRef)
    this.coreApi.appState.setState([controllerRef])

    const formRef = await this._getFormRef(
      containerRef,
      containerDefinition,
      controllerRef,
      pageRef
    )

    await this._updateFormStyle(formRef, box)

    await this._updateFormLayout(
      !!containerRef,
      this.isAppWidgetEnabled ? controllerRef : formRef,
      {
        width: box.data.layout.width,
        height: box.data.layout.height,
        preset: box.connectionConfig.preset,
      }
    )

    this.biLogger.log({
      evid: EVENTS.PANELS.addFormPanel.CHOOSE_TEMPLATE,
      template: presetKey,
      form_comp_id: formRef.id,
      source_name: source_name || initiator,
    })

    let customFields

    if (isADI(initiator)) {
      customFields = await this.coreApi.fields.fetchCustomFieldsByName()
    }

    const inputFields = fields.filter(field => isInputField(field.role))
    const nonInputComponents = fields.filter(field => !isInputField(field.role))

    const fieldsData = await Promise.all(
      inputFields.map(async field => {
        const fieldName = field.connectionConfig.crmLabel
        const fieldData = _.merge({}, field, {
          connectionConfig: {
            collectionFieldKey: _.camelCase(fieldName),
          },
        })

        if (isADI(initiator) && customFields) {
          const customFieldId = await this.coreApi.fields.getCustomFieldForField(
            customFields,
            fieldData
          )

          if (customFieldId) {
            fieldData.connectionConfig.customFieldId = customFieldId
          }
        }

        const { role, connectionConfig } = await this.coreApi.addComponentAndConnect(
          fieldData,
          controllerRef,
          formRef
        )
        return _.merge({ role }, connectionConfig)
      })
    )

    await Promise.all(
      nonInputComponents.map(component =>
        this.coreApi.addComponentAndConnect(component, controllerRef, formRef)
      )
    )

    if (!_.find(plugins, { id: FormPlugin.REGISTRATION_FORM }) && select_form) {
      this.boundEditorSDK.selection.selectComponentByCompRef({
        compsToSelect: [this.isAppWidgetEnabled ? controllerRef : formRef],
      })
    }

    if (_.find(plugins, { id: FormPlugin.PAYMENT_FORM })) {
      await this.coreApi.popNotificationAction({
        componentRef: formRef,
        plugins,
        notificationTrigger: NOTIFICATION_EVENTS.PAYMENT_FORM_ADDED,
      })
    }

    if (
      containerRef &&
      this.isAppWidgetEnabled &&
      !(await this.isContainerInAppWidget(containerRef))
    ) {
      this.boundEditorSDK.components.remove({ componentRef: containerRef })
    }

    addedFormsPromisesContainer[formRef.id] = new Promise(async resolve => {
      if (!containerRef) {
        this._createAutoCollection(formRef, { preset: presetKey, plugins }, fieldsData).then(
          async collectionId => {
            await Promise.all([
              this.coreApi.editDraft(formRef, formName, collectionId),
              this.coreApi.saveSite(),
            ])
            resolve()
          }
        )
      } else {
        await this.coreApi.editDraft(formRef, formName)
        resolve()
      }
    })

    return formRef
  }

  private async _updateFormLayout(
    isInstalledFromContainer: boolean,
    formRef: ComponentRef,
    { width, height, preset }
  ) {
    if (preset === FormPreset.REGISTRATION_FORM) {
      await this.coreApi.layout.centerComponentInsideLightbox(formRef)
    }
    await this.boundEditorSDK.components.layout.update({
      componentRef: formRef,
      layout: { height, width },
    })

    if (!isInstalledFromContainer) {
      await this._fixFormY(formRef)
    }
  }

  private _updateFormStyle(formRef: ComponentRef, box) {
    return this.boundEditorSDK.components.style.update({
      componentRef: formRef,
      style: _.get(box, 'data.style.style.properties'),
    })
  }

  private async _getFormRef(
    containerRef: ComponentRef,
    containerDefinition,
    controllerRef: ComponentRef,
    pageRef: ComponentRef
  ) {
    if (this.isAppWidgetEnabled) {
      if (containerRef && (await this.isContainerInAppWidget(containerRef))) {
        return containerRef
      } else {
        const components = await this.boundEditorSDK.controllers.getControllerConnections({
          controllerRef,
        })
        return components[0].componentRef
      }
    } else {
      if (containerRef) {
        await this.coreApi.connect(containerDefinition, controllerRef, containerRef)
        return containerRef
      } else {
        return (await this.coreApi.addComponentAndConnect(
          containerDefinition,
          controllerRef,
          pageRef
        )).connectToRef
      }
    }
  }

  private async _addController(
    containerRef: ComponentRef,
    containerDefinition,
    pageRef: ComponentRef
  ) {
    if (this.isAppWidgetEnabled) {
      return this._addAppWidgetController(containerRef, containerDefinition, pageRef)
    } else {
      return this._addAppController(pageRef)
    }
  }

  private async _addAppWidgetController(containerRef, containerDefinition, pageRef) {
    let containerOfForm
    if (containerRef) {
      const ancestors = await this.boundEditorSDK.components.getAncestors({
        componentRef: containerRef,
      })
      const firstAncestor = _.first(ancestors) as ComponentRef
      if (await this.coreApi.isAppWidget(firstAncestor)) {
        await this.coreApi.setComponentConnection(
          containerRef,
          containerDefinition.connectionConfig
        )
        return firstAncestor
      }
      const containerLayout = await this.boundEditorSDK.components.layout.get({
        componentRef: containerRef,
      })
      _.merge(containerDefinition.data.layout, _.pick(containerLayout, ['x', 'y']))
      containerOfForm = firstAncestor
    } else {
      containerOfForm = pageRef
    }
    return this.addAppWidget(containerOfForm, containerDefinition)
  }

  public async isContainerInAppWidget(containerRef: ComponentRef) {
    const ancestors = await this.boundEditorSDK.components.getAncestors({
      componentRef: containerRef,
    })
    const firstAncestor = _.first(ancestors)
    return this.coreApi.isAppWidget(firstAncestor)
  }

  public waitForAddedForm(formCompRef: ComponentRef): Promise<undefined> {
    return addedFormsPromisesContainer[formCompRef.id]
  }

  private async _addFromSnapshot(formDefinition, fields, pageRef, formRef: ComponentRef) {
    const controllerRef = await this._addAppController(pageRef)
    this.coreApi.appState.setState([controllerRef])
    formDefinition.connectionConfig.collectionId = this.getOldCollectionId(formDefinition, formRef)

    await this.coreApi.connect(formDefinition, controllerRef, formRef)

    const {
      data: {
        layout: { width, height },
      },
    } = formDefinition
    await this.boundEditorSDK.components.layout.update({
      componentRef: formRef,
      layout: { height, width },
    })

    await Promise.all(
      fields.map(field => this.coreApi.addComponentAndConnect(field, controllerRef, formRef))
    )

    this.coreApi.editDraft(formRef, formDefinition.connectionConfig.formName)
    return formRef
  }

  public getOldCollectionId(formDefinition, formRef: ComponentRef) {
    if (!_.get(formDefinition, 'connectionConfig.collectionId')) return
    const compIdAndRealCollectionId = formDefinition.connectionConfig.collectionId.split('_')
    if (compIdAndRealCollectionId.length > 1) {
      const realCollectionId = compIdAndRealCollectionId[1]
      return `${formRef.id}_${realCollectionId}`
    }
    return formDefinition.connectionConfig.collectionId
  }

  private async _addAppController(pageRef) {
    const appDefinitionId = await this.boundEditorSDK.info.getAppDefinitionId()
    const pageData = await this.boundEditorSDK.components.data.get({
      componentRef: pageRef,
    })

    return this.boundEditorSDK.components.add({
      componentDefinition: _.merge({}, APP_CONTROLLER_DEFINITION, {
        data: {
          applicationId: appDefinitionId,
        },
      }),
      pageRef: _.get(pageData, 'isPopup') ? pageRef : MASTER_PAGE,
    })
  }

  public async addAppWidget(pageRef, containerDefinition) {
    const appDefinitionId = await this.boundEditorSDK.info.getAppDefinitionId()
    const dataItemIdPlaceholder = 'data_item_id_placeholder'

    const container = _.merge({}, containerDefinition.data, {
      components: _.get(containerDefinition, 'data.components') || [],
      connections: {
        type: 'ConnectionList',
        items: [
          {
            type: 'ConnectionItem',
            role: ROLE_FORM,
            controllerId: dataItemIdPlaceholder,
            isPrimary: true,
            config: JSON.stringify(containerDefinition.connectionConfig),
          },
        ],
      },
    })

    return this.boundEditorSDK.components.add({
      componentDefinition: _.merge({}, APP_WIDGET_DEFINITION, {
        data: {
          applicationId: appDefinitionId,
          id: dataItemIdPlaceholder,
        },
        layout: containerDefinition.data.layout,
        components: [container],
      }),
      pageRef,
    })
  }

  private async _fixFormY(componentRef: ComponentRef): Promise<void> {
    const { height, y } = await this.boundEditorSDK.components.layout.get({
      componentRef,
    })
    this.boundEditorSDK.components.layout.update({
      componentRef,
      layout: { y: _.max([0, y - height / 2]) },
    })
  }

  private async _createAutoCollection(
    componentRef: ComponentRef,
    { preset, plugins }: { preset: FormPreset; plugins: Plugin[] },
    fieldsData,
    shouldCreateCollection = false
  ): Promise<string | null> {
    shouldCreateCollection = shouldCreateCollection || SHOULD_CREATE_COLLECTION[preset]

    if (!shouldCreateCollection) {
      return Promise.resolve(null)
    }

    const startCollectionBi = {
      template: preset,
      form_comp_id: componentRef.id,
      request_type: 'auto',
    }
    const endCollectionBi = {
      template: preset,
      form_comp_id: componentRef.id,
      request_type: 'auto',
    }

    const collectionId = await this.collectionsApi.createCollection(
      { preset },
      { startBi: startCollectionBi, endBi: endCollectionBi }
    )
    if (!collectionId) {
      return null
    }

    return Promise.all([
      this.coreApi.setComponentConnection(componentRef, {
        collectionId: `${componentRef.id}_${collectionId}`,
      }),
      this.collectionsApi.addFieldsToCollection(
        collectionId,
        fieldsData,
        plugins,
        (fieldComponent, fieldKey) =>
          this.coreApi.setComponentConnection(fieldComponent, { collectionFieldKey: fieldKey })
      ),
    ])
      .then(() => collectionId)
      .catch(() => this.collectionsApi.removeCollection(collectionId))
  }

  private async _getFormName(nameFromPreset, presetKey): Promise<string> {
    const controllers: any[] = await this.boundEditorSDK.controllers.listAllControllers()
    const formNames = await Promise.all(
      controllers.map(async ({ controllerRef }) => {
        const formRef = await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
        if (!formRef) {
          return ''
        }
        const config = await this.coreApi.getComponentConnection(formRef)
        return _.get(config, 'formName', '')
      })
    )

    return normalizeFormName(formNames, nameFromPreset, presetKey)
  }

  private async _createTag(formName: string): Promise<string | undefined> {
    return (await this.coreApi.createTag(formName)).id
  }

  public async createAutoCollection(componentRef: ComponentRef) {
    const fieldData = [
      'collectionFieldKey',
      'crmLabel',
      'crmTag',
      'crmType',
      'fieldType',
      'label',
      'role',
    ]
    const primaryConnection = await this.coreApi.getComponentConnection(componentRef)
    if (!_.get(primaryConnection, 'config')) {
      return
    }

    const { preset, collectionId, plugins } = primaryConnection.config
    if (collectionId) {
      return collectionId
    }

    return this.coreApi.fields
      .getFieldsSortByXY(componentRef, {
        allFieldsTypes: true,
      })
      .then(rawFieldsData => {
        const fieldsData = rawFieldsData.map(field => _.pick(field, fieldData))

        return this._createAutoCollection(componentRef, { preset, plugins }, fieldsData, true)
      })
      .catch(_.noop)
  }
}
