import * as _ from 'lodash'
import CoreApi from '../core-api'
import { withBi } from '../utils'
import { EVENTS } from '../../../constants/bi'
import { ComponentRef } from '../api-types'
import { createPanelDefs } from '../services/panel-definitions'
import { ROLE_FORM } from '../../../constants/roles'
import { BillingPanelReferrer } from '../../../constants/premium'
import { PanelName } from './consts/panel-names'
import { getPanelDefinitions } from '../../../editor-app/editor-app-impl'
import { getBaseUrl } from '../../../../src/utils/utils'

export default class ManagePanelsApi {
  private biLogger: any
  private boundEditorSDK: any
  private editorSDK: any
  private coreApi: CoreApi
  private experiments: any

  constructor(boundEditorSDK, editorSDK, coreApi: CoreApi, { experiments, biLogger }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.editorSDK = editorSDK
    this.experiments = experiments
  }

  public closePanel(panelToken, payload = null) {
    return this.editorSDK.editor.closePanel(panelToken, payload)
  }

  public openHelpPanel(helpId, onOpen = _.noop) {
    onOpen()
    return this.boundEditorSDK.editor.openHelpPanel({ helpId })
  }

  public openAddFormPanel(panelDefinitions) {
    return this.boundEditorSDK.editor.openModalPanel(panelDefinitions[PanelName.ADD_FORM]())
  }

  // _panelName is deprecated. ADI is loading one single page and we need to manage all panels there
  public openAdiPanel(_panelName: string, formRef: ComponentRef) {
    return Promise.resolve(
      `https://${getBaseUrl()}/statics/adi-panel.html?id=${formRef.id}&type=${formRef.type}`
    )
  }

  public async openFieldPanel(componentRef: ComponentRef, fieldsPanelToken, _biData = {}) {
    return this.coreApi.isConnectPanel()
      ? this._selectField(componentRef, fieldsPanelToken)
      : this._openFieldSettingsPanel(componentRef, _biData)
  }

  private async _selectField(componentRef: ComponentRef, fieldsPanelToken) {
    this.closePanel(fieldsPanelToken)
    return this.boundEditorSDK.selection.selectComponentByCompRef({ compsToSelect: [componentRef] })
  }

  @withBi({ startEvid: EVENTS.PANELS.fieldSettingsPanel.OPEN_PANEL })
  private async _openFieldSettingsPanel(componentRef: ComponentRef, _biData = {}) {
    return this._openFieldPanel(componentRef)
  }

  private async _openFieldPanel(componentRef: ComponentRef) {
    const [msid, { config }] = await this._fetchConfigAndMsid(componentRef)
    const mode = await this.boundEditorSDK.info.getEditorMode()

    return this.boundEditorSDK.editor.openComponentPanel(
      createPanelDefs(msid)[PanelName.FIELD_SETTINGS](
        componentRef,
        { config, componentRef },
        { mode, experiments: this.experiments }
      )
    )
  }

  public async openSettingsPanel(childRef: ComponentRef) {
    const componentRef = await this.coreApi.findComponentByRole(childRef, ROLE_FORM)
    const [msid, { config }] = await this._fetchConfigAndMsid(componentRef)

    return this.boundEditorSDK.editor.openComponentPanel(
      createPanelDefs(msid)[PanelName.FORM_SETTINGS](componentRef, { config, componentRef })
    )
  }

  public async openGetSubscribersFirstTimePanel(): Promise<any> {
    const panelDefinitions = getPanelDefinitions()
    const dontShowAgain = await this.coreApi.firstTimePanel.getSubscribersHideFirstTimePanel()

    if (dontShowAgain) {
      return
    }

    return this.boundEditorSDK.editor.openToolPanel(panelDefinitions[PanelName.FIRST_TIME]())
  }

  @withBi({ startEvid: EVENTS.PANELS.addFieldPanel.OPEN_PANEL })
  public async openAddFieldPanel(formContainerRef, panelToken, _biData = {}) {
    const [msid, { config }] = await this._fetchConfigAndMsid(formContainerRef)

    return this.editorSDK.editor.updatePanelOptions(
      panelToken,
      createPanelDefs(msid)[PanelName.ADD_FIELD](formContainerRef, {
        config,
        componentRef: formContainerRef,
      })
    )
  }

  @withBi({ startEvid: EVENTS.PANELS.upgradeAlertPanel.ACTION_CLICK })
  public closeUpgradeAlertPanel(token, _biData = {}) {
    return this.closePanel(token)
  }

  public async openPremiumBillingPanel(
    componentRef: ComponentRef,
    { referrer, alertType }: { referrer: BillingPanelReferrer; alertType: string }
  ) {
    const esi = await this.coreApi.getEditorSessionId()
    return this._openUpgradeAlertPanel(componentRef, alertType, {
      startBi: {
        form_comp_id: componentRef.id,
        esi,
        origin: referrer,
      },
    })
  }

  @withBi({ startEvid: EVENTS.PANELS.manageSubscribersPanel.OPEN_PANEL })
  public async openManageSubscribersPanel(panelDefinitions, _biData = {}) {
    return this.boundEditorSDK.editor.openModalPanel(
      panelDefinitions[PanelName.FORM_MANAGE_SUBSCRIBERS]()
    )
  }

  public async openMemberSignupPagePanel() {
    const currentPageRef = await this.editorSDK.document.pages.getCurrent()
    return this.boundEditorSDK.editor.openPagesPanel({ pageRef: currentPageRef })
  }

  public async openBusinessManagerPanel(entry) {
    return this.boundEditorSDK.editor.openDashboardPanel({ closeOtherPanels: false, url: entry })
  }

  @withBi({ startEvid: EVENTS.PANELS.addFieldPanel.SELECT_FIELD_TO_ADD })
  public openBlockedFieldAlert(componentRef: ComponentRef, { referrer, alertType }, _biData = {}) {
    return this.openPremiumBillingPanel(componentRef, { referrer, alertType })
  }

  public async openComponentPanel(
    componentRef: ComponentRef,
    panelName: PanelName,
    panelDefinitions,
    onOpen,
    externalData?
  ) {
    const { role, controllerRef, config } = await this.coreApi.getComponentConnection(componentRef)
    const formComponentRef =
      role == ROLE_FORM
        ? componentRef
        : await this.coreApi.findConnectedComponent(controllerRef, ROLE_FORM)
    const { plugins } = await this.coreApi.getFormConfigData(formComponentRef)
    config.plugins = plugins
    const isSiteSaved = await this.boundEditorSDK.info.isSiteSaved()
    if (!isSiteSaved) {
      await this.boundEditorSDK.editor.save()
      const emailId = await this.coreApi.getOwnerEmailId()

      await this.coreApi.setComponentConnection(formComponentRef, { emailId })
      config.emailId = emailId
    }
    const mode = await this.boundEditorSDK.info.getEditorMode()
    this.boundEditorSDK.editor.openComponentPanel(
      panelDefinitions[panelName](
        componentRef,
        _.merge({}, { config, componentRef }, externalData),
        { mode, experiments: this.experiments }
      )
    )
    onOpen(_.merge({}, config, { formId: formComponentRef.id }))
  }

  @withBi({ startEvid: EVENTS.PANELS.upgradeAlertPanel.OPEN_PANEL })
  private async _openUpgradeAlertPanel(
    componentRef: ComponentRef,
    alertType: string,
    _biData = {}
  ) {
    const [msid, { config }] = await this._fetchConfigAndMsid(componentRef)
    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.UPGRADE_ALERT](componentRef, {
        config,
        componentRef,
        alertType,
      })
    )
  }

  private _fetchConfigAndMsid(componentRef: ComponentRef): Promise<[string, { config }]> {
    return Promise.all([
      this.coreApi.getMetaSiteId(),
      this.coreApi.getComponentConnection(componentRef),
    ])
  }

  public openPublishSitePopup(componentRef: ComponentRef, msid: string) {
    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.PUBLISH_SITE_POP_UP](componentRef)
    )
  }

  @withBi({ startEvid: EVENTS.PANELS[PanelName.PAYMENT_WIZARD].PAYMENT_WIZARD_DISPLAYED })
  public openPaymentWizardPanel(componentRef: ComponentRef, msid, _biData = {}) {
    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.PAYMENT_WIZARD]({ componentRef })
    )
  }

  @withBi({ startEvid: EVENTS.PANELS[PanelName.DELETE_PAYMENT_ALERT].REMOVE_PAYMENT_POPUP_DISPLAYED })
  public openDeletePaymentPopup(componentRef: ComponentRef, msid: string, _biData = {}) {
    return this.boundEditorSDK.editor.openModalPanel(
      createPanelDefs(msid)[PanelName.DELETE_PAYMENT_ALERT](componentRef, { componentRef })
    )
  }
}
